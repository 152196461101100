import { render, staticRenderFns } from "./DocBox.vue?vue&type=template&id=407a2864&scoped=true&"
import script from "./DocBox.vue?vue&type=script&lang=js&"
export * from "./DocBox.vue?vue&type=script&lang=js&"
import style0 from "./DocBox.vue?vue&type=style&index=0&id=407a2864&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407a2864",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('407a2864')) {
      api.createRecord('407a2864', component.options)
    } else {
      api.reload('407a2864', component.options)
    }
    module.hot.accept("./DocBox.vue?vue&type=template&id=407a2864&scoped=true&", function () {
      api.rerender('407a2864', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8Fip41NYD6bb9/JS/comp/DocBox.vue"
export default component.exports