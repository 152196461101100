import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	computed: {
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},

	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		if ($(window).width() < 993) {
			//console.log("here" + $('#navrental').attr("data-toggle"));
			$('#Ferienwohnungen').removeAttr("data-toggle");
			$('#Ferienwohnungen').attr("data-toggle", "dropdown");
			$('#Ferienwohnungen').removeAttr("aria-expanded");
			$('#Ferienwohnungen').attr("aria-expanded", "true");
			//$('#navrental').attr("onClick", this.myFunction());

			$('#navregions').removeAttr("data-toggle");
			$('#navregions').attr("data-toggle", "dropdown");
			$('#navregions').removeAttr("aria-expanded");
			$('#navregions').attr("aria-expanded", "true");

			$('#navaboutus').removeAttr("data-toggle");
			$('#navaboutus').attr("data-toggle", "dropdown");
			$('#navaboutus').removeAttr("aria-expanded");
			$('#navaboutus').attr("aria-expanded", "true");

			$('#navaboutus1').removeAttr("data-toggle");
			$('#navaboutus1').attr("data-toggle", "dropdown");
			$('#navaboutus1').removeAttr("aria-expanded");
			$('#navaboutus1').attr("aria-expanded", "true");



		}

	},

	methods: {

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};