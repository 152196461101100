var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serviceid == 35445 ||
    _vm.serviceid == 20152 ||
    _vm.serviceid == 20153 ||
    _vm.serviceid == 20154 ||
    _vm.serviceid == 47694
    ? _c(
        "a",
        {
          attrs: {
            href: "javascript:0;",
            "data-toggle": "popover",
            title: _vm.getTitleText,
            "data-trigger": "hover",
            "data-placement": "top",
            "data-html": "true",
            "data-content": _vm.getServiceText
          }
        },
        [_c("i", { staticClass: "fa fa-info-circle" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }